var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"#2f6281","dark":""}},[_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"src":require('@/assets/my-playground-logo.png'),"contain":"","max-width":"120","alt":"My Playground Logo"}})],1)],1),_c('v-spacer'),(_vm.$vuetify.breakpoint.width > 500)?_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-btn',{attrs:{"large":"","depressed":"","text":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Home")])])],1),_c('v-menu',{staticStyle:{"z-index":"999"},attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"mx-0 mb-0",attrs:{"icon":"","small":""}},on),[_c('v-btn',{attrs:{"large":"","depressed":"","text":""}},[_vm._v("Page Builder"),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]}}],null,false,3739525164)},[_c('v-list',{staticClass:"py-0"},[_c('router-link',{attrs:{"to":"/grapesjs"}},[_c('v-list-item',{staticClass:"py-3"},[_c('v-list-item-title',[_vm._v("GrapesJS")])],1)],1),_c('router-link',{attrs:{"to":"/mypagebuilder"}},[_c('v-list-item',{staticClass:"py-3"},[_c('v-list-item-title',[_vm._v("HNFRDL Builder")])],1)],1)],1)],1),_c('router-link',{attrs:{"to":"/mycustom"}},[_c('v-btn',{attrs:{"large":"","depressed":"","text":""}},[_c('span',{staticClass:"mr-2"},[_vm._v("Custom Vuetify")])])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.width<=500)?_c('v-menu',{attrs:{"offset-y":"","left":"","bottom":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-xml")])],1)]}}],null,false,199906108)},[_c('v-list',[_c('router-link',{attrs:{"to":"/"}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Home")])],1)],1),_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-title',{attrs:{"icon":""}},[_vm._v("Page Builder"),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]}}],null,false,446050315)},[_c('v-list',{staticClass:"py-0"},[_c('router-link',{attrs:{"to":"/grapesjs"}},[_c('v-list-item',{staticClass:"pa-3"},[_c('v-list-item-title',[_vm._v("GrapesJS")])],1)],1),_c('router-link',{attrs:{"to":"/mypagebuilder"}},[_c('v-list-item',{staticClass:"pa-3"},[_c('v-list-item-title',[_vm._v("HNFRDL Builder")])],1)],1)],1)],1),_c('router-link',{attrs:{"to":"/mycustom"}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Custom Vuetify")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
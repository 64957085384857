<template lang="pug">
  .brand-banner
    div.banner.text-center
      p.main-body-text.mt-0.mb-3 Join the 10,000,000+ people meeting on Gather
      .brand-list
        img(src='https://via.placeholder.com/800x200?text=Logo Brand' alt='')
        img(src='https://via.placeholder.com/800x200?text=Logo Brand' alt='')
        img(src='https://via.placeholder.com/800x200?text=Logo Brand' alt='')
        img(src='https://via.placeholder.com/800x200?text=Logo Brand' alt='')
        img(src='https://via.placeholder.com/800x200?text=Logo Brand' alt='')
</template>
<script>
export default {
  name: "BrandBanner"
}
</script>
<template lang="pug">
  .cta-banner
    div.banner.text-center
      p.main-heading-text.mt-0.mb-3 Try Gather for free
      .d-flex.mx-auto(style='width: fit-content;')
        button.btn.bg-primary.btn-1.light.mx-2 Get Started
        button.btn.bg-primary-light.btn-1.text-primary.mx-2 Request Demo

</template>
<script>
export default {
  name: "CtaBanner"
}
</script>
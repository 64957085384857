var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticStyle:{"z-index":"150"},style:(_vm.$vuetify.breakpoint.width < 960 ? 'margin-top:54px !important' : 'margin-top:64px !important'),attrs:{"dense":"","dark":"","app":""}},[_c('router-link',{attrs:{"to":"/mypagebuilder","target":"_self"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}])},[_c('span',[_vm._v("Back to Pages")])])],1),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.downloadJSON}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("Download Layout File")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.dialog=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}])},[_c('span',[_vm._v("Upload Layout File")])]),_c('router-link',{attrs:{"to":("/mypagebuilder/preview/" + _vm.pageID),"target":"_blank"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1)]}}])},[_c('span',[_vm._v("Preview Layout")])])],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h5',[_vm._v("Upload Layout File")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"Choose Layout File","filled":"","rounded":"","hide-details":"auto","accept":"application/json"},model:{value:(_vm.importedFile),callback:function ($$v) {_vm.importedFile=$$v},expression:"importedFile"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.readFile}},[_vm._v("Upload")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
  .solutions-section
    div.section-120.bg-white.details
      v-row
        v-col.col.pt-5(cols="12" md="5")
          .media-container-1.ratio-1x1
            img(src='https://via.placeholder.com/800x600?text=Gambar Anda' height='100%' alt='')
        v-col.col.pt-4(cols="12" md="7")
          .section-heading-text.text-black.mt-lg-5 Make virtual interactions more human
          .section-body-text.text-black
            | Video chat shouldn&rsquo;t be awkward. Walking in and out of conversations feels natural and seamless in Gather.
          button.btn.bg-primary.text-white.btn-1.mt-4.mb-4 Fluid Video Chat
      v-row.reverse
        v-col.col.pt-5(cols="12" md="5")
          .media-container-1.ratio-1x1
            img(src='https://via.placeholder.com/800x600?text=Gambar Anda' height='100%' alt='')
        v-col.col.pt-4(cols="12" md="7")
          .section-heading-text.text-black.mt-lg-5 Make virtual interactions more human
          .section-body-text.text-black
            | Video chat shouldn&rsquo;t be awkward. Walking in and out of conversations feels natural and seamless in Gather.
          button.btn.bg-primary.text-white.btn-1.mt-4.mb-4 Fluid Video Chat
      v-row
        v-col.col.pt-5(cols="12" md="5")
          .media-container-1.ratio-1x1
            img(src='https://via.placeholder.com/800x600?text=Gambar Anda' height="100%"  alt='')
        v-col.col.pt-4(cols="12" md="7")
          .section-heading-text.text-black.mt-lg-5 Make virtual interactions more human
          .section-body-text.text-black
            | Video chat shouldn&rsquo;t be awkward. Walking in and out of conversations feels natural and seamless in Gather.
          button.btn.bg-primary.text-white.btn-1.mt-4.mb-4 Fluid Video Chat

</template>
<script>
export default {
  name: "SolutionsSection"
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editable)?_c('v-speed-dial',{staticClass:"btn-open",attrs:{"direction":"left","transition":"slide-x-reverse-transition","right":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{staticClass:"btn-speeddial",attrs:{"dark":"","small":"","fab":"","color":"primary"},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(!_vm.fab)?_c('v-icon',[_vm._v("mdi-dots-horizontal")]):_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}],null,false,1205748578),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-edit",attrs:{"fab":"","x-small":"","dark":"","color":"success"},on:{"click":function($event){return _vm.editDialog(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"edit-icon"},[_vm._v("mdi-pencil")])],1)]}}],null,false,2683685916)},[_c('span',[_vm._v("Edit Block")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-edit",attrs:{"fab":"","x-small":"","dark":"","color":"red"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"edit-icon",on:{"click":function($event){return _vm.editBlock(_vm.blockID,'delete')}}},[_vm._v("mdi-trash-can")])],1)]}}],null,false,2398446666)},[_c('span',[_vm._v("Delete Block")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-edit",attrs:{"fab":"","x-small":"","dark":"","color":"warning"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"edit-icon",on:{"click":function($event){return _vm.editBlock(_vm.blockIndex,'down')}}},[_vm._v("mdi-arrow-down-thick")])],1)]}}],null,false,3757742752)},[_c('span',[_vm._v("Move Block Down")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-edit",attrs:{"fab":"","x-small":"","dark":"","color":"warning"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"edit-icon",on:{"click":function($event){return _vm.editBlock(_vm.blockIndex,'up')}}},[_vm._v("mdi-arrow-up-thick")])],1)]}}],null,false,677672832)},[_c('span',[_vm._v("Move Block Up")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }